// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/opt/build/repo/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cheatsheet-js": () => import("/opt/build/repo/src/pages/cheatsheet.js" /* webpackChunkName: "component---src-pages-cheatsheet-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-counter-js": () => import("/opt/build/repo/src/pages/counter.js" /* webpackChunkName: "component---src-pages-counter-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kickass-me-js": () => import("/opt/build/repo/src/pages/kickass-me.js" /* webpackChunkName: "component---src-pages-kickass-me-js" */),
  "component---src-pages-project-peerreview-js": () => import("/opt/build/repo/src/pages/project-peerreview.js" /* webpackChunkName: "component---src-pages-project-peerreview-js" */),
  "component---src-pages-project-websites-js": () => import("/opt/build/repo/src/pages/project-websites.js" /* webpackChunkName: "component---src-pages-project-websites-js" */),
  "component---src-pages-project-wormapp-js": () => import("/opt/build/repo/src/pages/project-wormapp.js" /* webpackChunkName: "component---src-pages-project-wormapp-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

